import React, { FC, memo } from 'react';

export enum CarouselArrowDirection {
	LEFT = 'left',
	RIGHT = 'right',
}

export interface Props {
	direction: CarouselArrowDirection;
}

export const CarouselArrow: FC<Props> = memo(({ direction }) => (
	<span className={`b-carousel__arrow b-carousel__arrow--${direction}`} />
));
