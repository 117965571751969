import ReactCarousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import React, { FC, ReactNode } from 'react';
import { CarouselArrow, CarouselArrowDirection } from '../ui/block/CarouselArrow';

interface Props {
	children: ReactNode;
}

const Carousel: FC<Props> = ({ children }) => {
	return (
		<div className="b-carousel">
			<ReactCarousel
				addArrowClickHandler
				arrowLeft={<CarouselArrow direction={CarouselArrowDirection.LEFT} />}
				arrowRight={<CarouselArrow direction={CarouselArrowDirection.RIGHT} />}
				slidesPerPage={3}
				slidesPerScroll={1}
				infinite
				breakpoints={{
					1000: {
						slidesPerPage: 2,
					},
					750: {
						slidesPerPage: 1,
					},
				}}
			>
				{children}
			</ReactCarousel>
		</div>
	);
};

export default Carousel;
